var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as constants from "../../constants";
import { isNil, pick } from "lodash";
import { ADDITIONAL_CHAT_STATUS, API_PATH, CALL_TO_ACTION_TYPES, CHAT_STATUS, MESSAGE_STATUS, RICH_TEMPLATE_BUTTON_TYPE, TEMPLATE_RADIO_STATUSES, TEMPLATE_USAGE, LANGUAGES_TYPES, TEMPLATE_FOOTER_MESSAGES, } from "../../constants";
import { action, computed, observable, runInAction, makeObservable, reaction } from "mobx";
import { getAxios } from '../../backend';
import { getProjectId } from "../../settings";
import { EventName, WhatsApp, MessagingChannel, TEMPLATE_HEADER_TYPE, } from "@whatsper/texterchat-common";
import { has4ConsecutiveSpaces, hasTab } from "../../helpers/string";
import { message } from "antd";
import i18n from "../../i18n";
/**
 * Minimal timeout between template syncs for account
 */
const TPL_SYNC_MIN_TIMEOUT = 1000 * 60 * 15; // 15 minutes
/**
 * @todo REFACTORING REQUIRED! So far only basic conversion to Typescript done
 */
class TemplateMessagesStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "templateMessagesLists", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "templateMessagesLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isNewTemplateDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        //Template Params
        Object.defineProperty(this, "templateTitle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ""
        });
        Object.defineProperty(this, "templateName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ""
        });
        Object.defineProperty(this, "templateUsage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: TEMPLATE_USAGE.INBOX
        });
        Object.defineProperty(this, "templateDepartments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "templateLanguage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: LANGUAGES_TYPES.HE
        });
        Object.defineProperty(this, "templateCategory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: WhatsApp.TemplateCategory.MARKETING
        });
        Object.defineProperty(this, "templateMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ""
        });
        Object.defineProperty(this, "nextPlaceholderNumber", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 1
        });
        //Template State
        Object.defineProperty(this, "currentTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "templateEditing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "templateCreating", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "templateDeleting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        //Advansed Settings
        Object.defineProperty(this, "templateChatStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: CHAT_STATUS.BOT
        });
        Object.defineProperty(this, "templateChatRadioStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: TEMPLATE_RADIO_STATUSES.TAKEN
        });
        Object.defineProperty(this, "setBotNode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ""
        });
        Object.defineProperty(this, "templateReplyText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ""
        });
        Object.defineProperty(this, "responsibleAgent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "responsibleDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "attachedFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "botNodeLists", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "botNodeListLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "templateFieldMissing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "isDefaultTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        //Submit params
        Object.defineProperty(this, "submitTemplateLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "submitTemplateError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "submitWhatsAppLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "submitWhatsAppError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        //Rich template params
        Object.defineProperty(this, "richTemplateHeader", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "richTemplateHeaderType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "richTemplateHeaderUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: constants.DEFAULT_EXAMPLE_TEMPLATE_IMG_URL
        });
        Object.defineProperty(this, "richTemplateFooter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "richTemplateButtonType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "quickReplyButtons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "callToActionButtons", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "bodyExampleParams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "bodyExampleTemporaryParams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "showManagerCancelModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "templateTierLimitCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "templateTierLimitLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "selectedAccountId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /**
         * Timestamps when some channel account was last time synchronized with WhatsApp (if was).
         * (Doesn't needs to be observable)
         */
        Object.defineProperty(this, "lastSyncOn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        /**
         * Load template messages for specified WhatsApp account
         *
         * @param accountId WhatsApp account ID
         * @returns
         */
        Object.defineProperty(this, "loadTemplateMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (accountId) => __awaiter(this, void 0, void 0, function* () {
                if (!accountId) {
                    throw new Error('Account ID is required');
                }
                // TODO: Think how to better handle this 
                // /* Load once and update through events  */
                // if (this.templateMessagesLists[accountId]) {
                //     console.debug(`Not loading templates for account ${accountId} because already loaded`);
                //     return;
                // }
                try {
                    runInAction(() => this.templateMessagesLoading = true);
                    const templates = yield this.getTemplateMessages(accountId);
                    runInAction(() => {
                        this.templateMessagesLists[accountId] = templates;
                    });
                }
                catch (error) {
                    console.error("ERROR: loading message templates failed", error);
                }
                finally {
                    runInAction(() => this.templateMessagesLoading = false);
                }
            })
        });
        Object.defineProperty(this, "getTemplateMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (accountId) => __awaiter(this, void 0, void 0, function* () {
                const { data: { templates } } = yield getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.TEMPLATES}/${accountId}`);
                });
                return templates
                    .filter((tmp) => { var _a, _b; return ((_b = (_a = tmp.provider_template) === null || _a === void 0 ? void 0 : _a.localizations) === null || _b === void 0 ? void 0 : _b.length) || Object.keys(tmp.localizationDrafts || {}).length; })
                    .sort((tmp1, tmp2) => tmp1.created > tmp2.created ? -1 : 1)
                    .map((tmp) => this.parseTemplate(tmp));
            })
        });
        /**
         * Return already loaded WhatsApp templates for specified WhatsApp account when loaded,
         * or start loading them and return empty array
         *
         * @param accountId
         * @returns
         */
        Object.defineProperty(this, "getOrLoadTemplateMessagesListByAccountId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (accountId) => {
                if (!this.templateMessagesLists[accountId]) {
                    this.loadTemplateMessages(accountId);
                    return [];
                }
                return this.templateMessagesLists[accountId];
            }
        });
        /**
         * Synchronize template messages for specified WhatsApp account with stored templates
         *
         * @param accountId WhatsApp account ID
         * @returns
         */
        Object.defineProperty(this, "syncTemplateMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (accountId) => __awaiter(this, void 0, void 0, function* () {
                if (this.lastSyncOn[accountId] && Date.now() - this.lastSyncOn[accountId] < TPL_SYNC_MIN_TIMEOUT) {
                    console.debug(`Not synchronizing templates for account ${accountId} because was too recently`);
                    return;
                }
                yield getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.TEMPLATES}/${accountId}/sync`);
                })
                    .then(() => {
                    this.lastSyncOn[accountId] = Date.now();
                });
            })
        });
        Object.defineProperty(this, "fetchBotNodeList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (botName) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const botNodeKey = botName || 'default';
                /* Don't fetch again bot node list if now < 15 seconds */
                if (((_a = this.botNodeLists[botNodeKey]) === null || _a === void 0 ? void 0 : _a.expiry) > Date.now()) {
                    return;
                }
                runInAction(() => {
                    this.botNodeListLoading = true;
                });
                return yield getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.BOTS}/flow/node-list${botName ? '/' + botName : ''}`);
                })
                    .then(({ data }) => {
                    runInAction(() => {
                        this.botNodeLists[botNodeKey] = {
                            data,
                            expiry: Date.now() + 1000 * 15, // 15 seconds
                        };
                    });
                })
                    .catch(e => console.error(e))
                    .finally(() => {
                    runInAction(() => {
                        this.botNodeListLoading = false;
                    });
                });
            })
        });
        Object.defineProperty(this, "getBotNodeList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (botName) => {
                var _a;
                const botNodeKey = botName || 'default';
                return ((_a = this.botNodeLists[botNodeKey]) === null || _a === void 0 ? void 0 : _a.data) || [];
            }
        });
        Object.defineProperty(this, "sendTemplateMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (templateMessage, messageText, parameters) => __awaiter(this, void 0, void 0, function* () {
                var _b, _c, _d, _e, _f, _g, _h;
                const { activeChatStore } = this.rootStore;
                const timestamp = Math.floor(new Date().getTime() / 1000);
                const message = {
                    messageId: `${this.rootStore._new_message_id}_${this.rootStore._new_message_counter++}`,
                    status: MESSAGE_STATUS.MESSAGE_SENT,
                    incoming: false,
                    parent_chat: (_b = this.rootStore.activeChatStore.chat) === null || _b === void 0 ? void 0 : _b._id,
                    received: Date.now(),
                    tmpSendId: timestamp,
                    text: messageText
                };
                const request = {
                    chatId: message.parent_chat,
                    templateMessage: {
                        text: messageText,
                        templateId: templateMessage.name,
                        localization: templateMessage.language,
                        tmpSendId: timestamp,
                        parameters: parameters,
                        chatStatus: CHAT_STATUS.BULK,
                        sendingAgent: (_c = this.rootStore.loginStore.userDetails) === null || _c === void 0 ? void 0 : _c._id,
                        departmentId: (_d = activeChatStore.chat) === null || _d === void 0 ? void 0 : _d.departmentId,
                    }
                };
                if (this.templateChatRadioStatus === TEMPLATE_RADIO_STATUSES.TAKEN) {
                    request.templateMessage.chatStatus = CHAT_STATUS.ASSIGNED;
                    request.templateMessage.agent = (_e = this.rootStore.loginStore.userDetails) === null || _e === void 0 ? void 0 : _e._id;
                    request.templateMessage.replyAction = null;
                }
                else {
                    request.templateMessage.replyAction = yield this.createTemplateReplyAction();
                }
                if ([TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.VIDEO].includes((_f = templateMessage.header) === null || _f === void 0 ? void 0 : _f.type) && (this.richTemplateHeader || this.richTemplateHeaderUrl)) {
                    try {
                        const file = this.richTemplateHeader;
                        const filename = TEMPLATE_HEADER_TYPE.DOCUMENT === ((_g = templateMessage.header) === null || _g === void 0 ? void 0 : _g.type) ? file === null || file === void 0 ? void 0 : file.name : void 0;
                        const url = file ? yield this.rootStore.files.uploadFileAndShare(file, this.rootStore.files.getAgentUploadDirectory()) : this.richTemplateHeaderUrl;
                        request.templateMessage.header = {
                            type: (_h = templateMessage.header) === null || _h === void 0 ? void 0 : _h.type,
                            filename,
                            url,
                        };
                    }
                    catch (error) {
                        console.error('Error uploading file', error);
                        throw new Error('Error uploading file');
                    }
                }
                else if (templateMessage.header && templateMessage.header.type === TEMPLATE_HEADER_TYPE.TEXT && this.richTemplateHeader) {
                    request.templateMessage.header = {
                        type: TEMPLATE_HEADER_TYPE.TEXT,
                        text: this.richTemplateHeader
                    };
                }
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.post(`/templates/send`, request);
                    })
                        .then((response) => {
                        if (!!response && !!response.data && !!response.data.messageId) {
                            message.messageId = response.data.messageId;
                            message.status = constants.MESSAGE_STATUS.MESSAGE_DELIEVERED;
                        }
                        else {
                            message.status = constants.MESSAGE_STATUS.MESSAGE_FAILED;
                        }
                        this.setStoreToDefault();
                    });
                }
                catch (error) {
                    console.error('Sending template message to failed', error);
                    throw new Error('Error sending template message');
                }
            })
        });
        Object.defineProperty(this, "createTemplateReplyAction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _j, _k;
                const currentDate = new Date();
                currentDate.setDate(currentDate.getDate() + 3);
                const replyAction = {
                    chatStatus: this.templateChatStatus,
                    expirationTime: 60 * 60 * 24 * 3,
                    resolveTime: currentDate.getTime(),
                    created: Math.floor(Date.now() / 1000),
                };
                if (this.setBotNode && this.templateChatStatus === CHAT_STATUS.BOT) {
                    replyAction.setBotNode = this.setBotNode;
                }
                else if (!this.setBotNode && this.templateChatStatus === CHAT_STATUS.BOT) {
                    replyAction.setBotNode = "start";
                }
                if (this.templateReplyText && this.templateChatStatus !== CHAT_STATUS.BOT) {
                    replyAction.replyText = this.templateReplyText;
                }
                if (this.attachedFile && !((_j = this.attachedFile) === null || _j === void 0 ? void 0 : _j.url) && this.templateChatStatus !== CHAT_STATUS.BOT) {
                    try {
                        const url = yield this.rootStore.files.uploadFileAndShare(this.attachedFile, this.rootStore.files.getAgentUploadDirectory());
                        replyAction.replyToTemplateFile = {
                            name: this.attachedFile.name,
                            url
                        };
                    }
                    catch (error) {
                        console.error('Error uploading file', error);
                        throw new Error('Error uploading file');
                    }
                }
                else if (this.attachedFile && this.attachedFile.url && this.templateChatStatus !== CHAT_STATUS.BOT) {
                    replyAction.replyToTemplateFile = {
                        name: this.attachedFile.name,
                        url: this.attachedFile.url
                    };
                }
                if (this.responsibleAgent && this.templateChatStatus !== CHAT_STATUS.BOT) {
                    replyAction.responsibleAgent = this.responsibleAgent;
                }
                if (this.responsibleDepartment && this.templateChatStatus !== CHAT_STATUS.BOT) {
                    replyAction.responsibleDepartment = this.responsibleDepartment;
                }
                if ((this.templateChatStatus === CHAT_STATUS.ASSIGNED && !this.responsibleAgent && !this.responsibleDepartment)
                    || this.templateChatStatus === ADDITIONAL_CHAT_STATUS.PENDING_SELF) {
                    replyAction.responsibleAgent = (_k = this.rootStore.loginStore.userDetails) === null || _k === void 0 ? void 0 : _k._id;
                }
                if (this.templateChatStatus === ADDITIONAL_CHAT_STATUS.PENDING_SELF) {
                    replyAction.chatStatus = CHAT_STATUS.ASSIGNED;
                }
                return replyAction;
            })
        });
        Object.defineProperty(this, "createTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _l;
                const accountId = (_l = this.selectedAccountId) === null || _l === void 0 ? void 0 : _l.id;
                if (!accountId) {
                    console.error('No account selected');
                    return;
                }
                try {
                    this.submitTemplateLoading = true;
                    const projectId = yield getProjectId();
                    const templateData = {
                        name: this.templateName.trim(),
                        usage: this.templateUsage,
                        language: this.templateLanguage,
                        category: this.templateCategory,
                        departments: this.templateDepartments,
                        title: this.templateTitle.trim(),
                        chatStatus: this.templateChatStatus,
                        from: projectId,
                        responsibleAgent: null,
                        responsibleDepartment: null,
                        isDefault: this.isDefaultTemplate,
                    };
                    templateData.components = this.createRichTemplateParams();
                    if (this.richTemplateHeaderType && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.VIDEO].includes(this.richTemplateHeaderType) && this.richTemplateHeader) {
                        const file = this.richTemplateHeader;
                        const fileUrl = yield this.rootStore.files.uploadFileAndShare(file, this.rootStore.files.getAgentUploadDirectory());
                        templateData.components.forEach((component) => {
                            if (component.type === WhatsApp.TemplateComponentType.HEADER) {
                                component.example = {
                                    header_handle: [fileUrl],
                                };
                            }
                        });
                    }
                    if (this.templateChatRadioStatus === TEMPLATE_RADIO_STATUSES.TAKEN) {
                        templateData.chatStatus = CHAT_STATUS.PENDING;
                    }
                    else {
                        if (this.templateChatStatus === ADDITIONAL_CHAT_STATUS.PENDING_SELF) {
                            templateData.chatStatus = CHAT_STATUS.ASSIGNED;
                        }
                        else if (this.templateChatStatus !== CHAT_STATUS.BOT) {
                            if (this.responsibleAgent) {
                                templateData.responsibleAgent = this.responsibleAgent;
                            }
                            if (this.responsibleDepartment) {
                                templateData.responsibleDepartment = this.responsibleDepartment;
                            }
                        }
                        if (this.setBotNode) {
                            templateData.setBotNode = this.setBotNode;
                        }
                        if (this.templateReplyText && this.templateChatStatus !== CHAT_STATUS.BOT) {
                            templateData.replyText = this.templateReplyText;
                        }
                        if (this.attachedFile && this.attachedFile.url && this.templateChatStatus !== CHAT_STATUS.BOT) {
                            templateData.attachedFile = {
                                name: this.attachedFile.name,
                                url: this.attachedFile.url
                            };
                        }
                        else if (this.attachedFile && !this.attachedFile.url && this.templateChatStatus !== CHAT_STATUS.BOT) {
                            const url = yield this.rootStore.files.uploadFileAndShare(this.attachedFile, this.rootStore.files.getAgentUploadDirectory());
                            templateData.attachedFile = {
                                name: this.attachedFile.name,
                                url
                            };
                        }
                    }
                    const templateParams = pick(templateData, [
                        'name',
                        'title',
                        'category',
                        'chatStatus',
                        'departments',
                        'setBotNode',
                        'responsibleAgent',
                        'responsibleDepartment',
                        'replyText',
                        'usage',
                        'isDefault',
                    ]);
                    const templateLocalizations = pick(templateData, [
                        'language',
                        'components'
                    ]);
                    const axios = yield getAxios();
                    const { data: template } = yield axios.post(`${API_PATH.TEMPLATES}/${accountId}`, templateParams);
                    try {
                        yield axios.post(`${API_PATH.TEMPLATES}/${accountId}/${template.name}/localizations`, templateLocalizations);
                    }
                    catch (error) {
                        // Temporary fix
                        // Try to delete newly added template without localizations
                        try {
                            yield axios.delete(`${API_PATH.TEMPLATES}/${accountId}/${template.name}`);
                        }
                        catch (error) {
                            console.error(error);
                        }
                        throw error;
                    }
                    this.setStoreToDefault();
                }
                catch (error) {
                    runInAction(() => {
                        this.submitTemplateError = error.message;
                        this.submitTemplateLoading = false;
                    });
                    throw error;
                }
            })
        });
        Object.defineProperty(this, "toggleNewTemplateDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isNewTemplateDrawer = !this.isNewTemplateDrawer;
            }
        });
        Object.defineProperty(this, "handleEditTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _m, _o, _p;
                const accountId = (_m = this.selectedAccountId) === null || _m === void 0 ? void 0 : _m.id;
                if (!accountId) {
                    console.error('No account selected');
                    return;
                }
                try {
                    let templateData = {
                        title: this.templateTitle.trim(),
                        departments: this.templateDepartments,
                        category: this.templateCategory,
                        message: this.templateMessage,
                        language: this.templateLanguage,
                        usage: this.templateUsage,
                        chatStatus: this.templateChatStatus,
                        responsibleAgent: null,
                        responsibleDepartment: null,
                        isDefault: this.isDefaultTemplate,
                    };
                    this.submitTemplateLoading = true;
                    templateData.components = this.createRichTemplateParams();
                    if (this.richTemplateHeaderType && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.VIDEO].includes(this.richTemplateHeaderType)) {
                        const file = this.richTemplateHeader;
                        if (this.richTemplateHeader) {
                            const fileUrl = yield this.rootStore.files.uploadFileAndShare(file, this.rootStore.files.getAgentUploadDirectory());
                            templateData.components.forEach((component) => {
                                if (component.type === WhatsApp.TemplateComponentType.HEADER) {
                                    component.example = {
                                        header_handle: [fileUrl],
                                    };
                                }
                            });
                        }
                    }
                    if (this.templateChatRadioStatus === TEMPLATE_RADIO_STATUSES.TAKEN) {
                        templateData.chatStatus = CHAT_STATUS.PENDING;
                    }
                    else {
                        if (this.templateChatStatus === ADDITIONAL_CHAT_STATUS.PENDING_SELF) {
                            templateData.chatStatus = CHAT_STATUS.ASSIGNED;
                        }
                        else if (this.templateChatStatus !== CHAT_STATUS.BOT) {
                            if (this.responsibleAgent) {
                                templateData.responsibleAgent = this.responsibleAgent;
                            }
                            if (this.responsibleDepartment) {
                                templateData.responsibleDepartment = this.responsibleDepartment;
                            }
                        }
                        if (this.setBotNode) {
                            templateData.setBotNode = this.setBotNode;
                        }
                        if (this.templateReplyText && this.templateChatStatus !== CHAT_STATUS.BOT) {
                            templateData.replyText = this.templateReplyText;
                        }
                        if (this.attachedFile && this.attachedFile.url && this.templateChatStatus !== CHAT_STATUS.BOT) {
                            templateData.attachedFile = {
                                name: this.attachedFile.name,
                                url: this.attachedFile.url
                            };
                        }
                        else if (this.attachedFile && !this.attachedFile.url && this.templateChatStatus !== CHAT_STATUS.BOT) {
                            const url = yield this.rootStore.files.uploadFileAndShare(this.attachedFile, this.rootStore.files.getAgentUploadDirectory());
                            templateData.attachedFile = {
                                name: this.attachedFile.name,
                                url
                            };
                        }
                    }
                    const templateParams = pick(templateData, [
                        'title',
                        'chatStatus',
                        'departments',
                        'setBotNode',
                        'responsibleAgent',
                        'responsibleDepartment',
                        'replyText',
                        'usage',
                        'isDefault',
                    ]);
                    // Currently we not support editing template localization on inbox side,
                    // when template have status other than "NEW",
                    // but when it will be decided to implement, 
                    // then "templateLocalizationEditable" WhatsApp feature should be checked before allowing this
                    let templateLocalization, status = 'NEW';
                    for (const template of this.templateMessagesLists[accountId] || []) {
                        if (template.name === ((_o = this.currentTemplate) === null || _o === void 0 ? void 0 : _o.name) && template.status) {
                            status = template.status;
                            break;
                        }
                    }
                    if (status === 'NEW') {
                        templateLocalization = pick(templateData, [
                            'language',
                            'components',
                        ]);
                    }
                    const axios = yield getAxios();
                    yield axios.patch(`${API_PATH.TEMPLATES}/${accountId}/${(_p = this.currentTemplate) === null || _p === void 0 ? void 0 : _p.name}`, templateParams)
                        .then(({ data }) => __awaiter(this, void 0, void 0, function* () {
                        if (templateLocalization) {
                            return axios.post(`${API_PATH.TEMPLATES}/${accountId}/${data.name}/localizations`, templateLocalization);
                        }
                    }));
                    this.setStoreToDefault();
                }
                catch (error) {
                    runInAction(() => {
                        this.submitTemplateError = error.message;
                        this.submitTemplateLoading = false;
                    });
                    throw error;
                }
            })
        });
        Object.defineProperty(this, "handleDeleteTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _q, _r, _s;
                const accountId = (_q = this.selectedAccountId) === null || _q === void 0 ? void 0 : _q.id;
                if (!accountId) {
                    console.error('No account selected');
                    return;
                }
                const name = ((_r = this.currentTemplate) === null || _r === void 0 ? void 0 : _r.obj.templateId) || ((_s = this.currentTemplate) === null || _s === void 0 ? void 0 : _s.name);
                this.submitTemplateLoading = true;
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.delete(`${API_PATH.TEMPLATES}/${accountId}/${name}`);
                    });
                    this.setStoreToDefault();
                }
                catch (error) {
                    console.error(error);
                }
                finally {
                    runInAction(() => {
                        this.submitTemplateLoading = false;
                    });
                }
            })
        });
        Object.defineProperty(this, "handleSubmitToWhatsApp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _t;
                const accountId = (_t = this.selectedAccountId) === null || _t === void 0 ? void 0 : _t.id;
                if (!accountId) {
                    console.error('No account selected');
                    return;
                }
                this.submitWhatsAppLoading = true;
                this.submitWhatsAppError = null;
                const errorKey = this.checkTemplateForErrorsBeforeSubmit();
                if (errorKey) {
                    runInAction(() => {
                        this.submitWhatsAppLoading = false;
                    });
                    message.error(i18n.t(errorKey), 5);
                    return;
                }
                return yield getAxios()
                    .then((axios) => {
                    var _a;
                    return axios.post(`${API_PATH.TEMPLATES}/${accountId}/${(_a = this.currentTemplate) === null || _a === void 0 ? void 0 : _a.name}/localizations/${this.templateLanguage}/submit`);
                })
                    .then(() => {
                    runInAction(() => {
                        var _a;
                        const index = (_a = this.templateMessagesLists[accountId]) === null || _a === void 0 ? void 0 : _a.findIndex(templ => { var _a; return templ.name === ((_a = this.currentTemplate) === null || _a === void 0 ? void 0 : _a.name); });
                        this.templateMessagesLists[accountId][index].obj.localizations[0].status = WhatsApp.TemplateLocalizationStatus.PENDING;
                    });
                    this.setStoreToDefault();
                    message.success(i18n.t('submitTemplateSucccess'), 5);
                })
                    .catch((e) => {
                    runInAction(() => {
                        message.error(i18n.t('unexpectedError'), 5);
                    });
                    console.error(e);
                })
                    .finally(() => {
                    runInAction(() => {
                        this.submitWhatsAppLoading = false;
                    });
                });
            })
        });
        Object.defineProperty(this, "checkTemplateForErrorsBeforeSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.hasAllSampleParamsAdded) {
                    return 'pleaseProvideSampleData';
                }
                if (has4ConsecutiveSpaces(this.templateMessage)) {
                    return 'has4ConsecutiveSpacesOrNewLines';
                }
                if (hasTab(this.templateMessage)) {
                    return 'hasTabChar';
                }
                if (this.richTemplateHeaderType === TEMPLATE_HEADER_TYPE.TEXT && !this.richTemplateHeader) {
                    return 'headerTextEmpty';
                }
            }
        });
        Object.defineProperty(this, "checkTemplateForErrorsBeforeEdit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.richTemplateHeaderType === TEMPLATE_HEADER_TYPE.TEXT && !this.richTemplateHeader) {
                    return 'headerTextEmpty';
                }
            }
        });
        Object.defineProperty(this, "getTierLimitCount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                // THIS FUNCTIONALITY BROKEN SINCE v6
                // BECAUSE SERVER-SIDE DOESN'T EXISTS ANYMORE
                // const params: StatsCountUniqueContactsGetQuery = {
                //     afterTimestamp: (new Date().getTime() - 24 * 60 * 60 * 1000).toString(),
                // };
                // runInAction(() => {
                //     this.templateTierLimitLoading = true;
                // });
                // return await getAxios()
                //     .then(async (axios) => {
                //         return axios.get<StatsCountUniqueContactsGetResponse>(
                //             `${API_PATH.TEMPLATES}/stats/count/sent-unique-contacts/whatsapp/accepted`,
                //             {
                //                 params,
                //             }
                //         );
                //     })
                //     .then(({ data }) => {
                //         runInAction(() => {
                //             this.templateTierLimitCount = data.count;
                //         });
                //     })
                //     .catch((error) => {
                //         console.error('Error getting tier limit count', error);
                //     })
                //     .finally(() => {
                //         runInAction(() => {
                //             this.templateTierLimitLoading = false;
                //         });
                //     });
            })
        });
        Object.defineProperty(this, "createRichTemplateParams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const richParams = [];
                if (this.richTemplateHeaderType) {
                    const header = {
                        type: WhatsApp.TemplateComponentType.HEADER,
                        format: this.richTemplateHeaderType,
                    };
                    if (this.richTemplateHeaderType === TEMPLATE_HEADER_TYPE.TEXT) {
                        header.text = this.richTemplateHeader;
                    }
                    if (this.richTemplateHeaderType && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.VIDEO].includes(this.richTemplateHeaderType)) {
                        header.example = {
                            header_handle: [this.richTemplateHeaderUrl],
                        };
                    }
                    richParams.push(header);
                }
                const body = {
                    type: WhatsApp.TemplateComponentType.BODY,
                    text: this.templateMessage,
                };
                if (((_a = this.bodyExampleParams) === null || _a === void 0 ? void 0 : _a.filter(p => p !== null).length) > 0
                    && !(this.bodyExampleParams.length < this.nextPlaceholderNumber - 1)) {
                    body.example = {
                        body_text: [this.bodyExampleParams],
                    };
                }
                richParams.push(body);
                if (this.richTemplateFooter) {
                    const footer = {
                        type: WhatsApp.TemplateComponentType.FOOTER,
                        text: this.richTemplateFooter,
                    };
                    richParams.push(footer);
                }
                if (this.quickReplyButtons || this.callToActionButtons) {
                    const buttons = { type: WhatsApp.TemplateComponentType.BUTTONS, buttons: [] };
                    if (this.quickReplyButtons) {
                        this.quickReplyButtons.map(({ text }) => {
                            buttons.buttons.push({
                                type: WhatsApp.TemplateButtonType.QUICK_REPLY,
                                text,
                            });
                        });
                    }
                    ;
                    if (this.callToActionButtons) {
                        this.callToActionButtons.map(({ type, text, url, phone_number }) => {
                            if (phone_number) {
                                buttons.buttons.push({ type, text, phone_number });
                            }
                            if (url) {
                                buttons.buttons.push({ type, text, url });
                            }
                        });
                    }
                    richParams.push(buttons);
                }
                return richParams;
            }
        });
        /**
         * @todo Rewrite this because looks awful
         *
         * @param agent
         * @param departmentId
         */
        Object.defineProperty(this, "setResponsibleAgentOrDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (agent, departmentId) => {
                var _a;
                this.templateFieldMissing = null;
                if (agent) {
                    this.responsibleAgent = agent;
                    this.responsibleDepartment = null;
                }
                else {
                    this.responsibleAgent = null;
                    this.responsibleDepartment = ((_a = this.rootStore.departmentsStore.departments.find(department => department._id === departmentId)) === null || _a === void 0 ? void 0 : _a._id) || null;
                }
            }
        });
        Object.defineProperty(this, "setAttachedFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (file) => {
                this.attachedFile = file;
            }
        });
        Object.defineProperty(this, "setNewTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.templateCreating = !this.templateCreating;
            }
        });
        Object.defineProperty(this, "setTemplateEdited", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (template) => {
                var _a;
                if (template) {
                    const chatStatus = template.chatStatus || CHAT_STATUS.BOT;
                    const placeholderNumber = (((_a = template.text.match(/{{(.*?)}}/g)) === null || _a === void 0 ? void 0 : _a.length) || 0) + 1;
                    this.currentTemplate = template;
                    this.templateEditing = true;
                    this.templateCreating = false;
                    this.templateTitle = template.title;
                    this.templateUsage = template.usage;
                    this.templateDepartments = template.departments;
                    this.templateLanguage = template.language;
                    this.templateCategory = template.category;
                    this.templateMessage = template.text;
                    this.bodyExampleParams = [];
                    this.nextPlaceholderNumber = placeholderNumber || 1;
                    this.isDefaultTemplate = !!template.isDefault;
                    template.obj.localizations[0].components.forEach((component) => {
                        var _a;
                        if (component.type === WhatsApp.TemplateComponentType.BODY && component.example) {
                            this.bodyExampleParams = (_a = component.example.body_text) === null || _a === void 0 ? void 0 : _a[0];
                        }
                    });
                    if (chatStatus === CHAT_STATUS.ASSIGNED && !(template.responsibleAgent || template.responsibleDepartment)) {
                        this.templateChatStatus = ADDITIONAL_CHAT_STATUS.PENDING_SELF;
                        this.responsibleAgent = null;
                        this.responsibleDepartment = null;
                    }
                    else {
                        this.templateChatStatus = chatStatus;
                        if (template.responsibleAgent) {
                            this.responsibleAgent = template.responsibleAgent;
                        }
                        else {
                            this.responsibleAgent = null;
                        }
                        if (template.responsibleDepartment) {
                            this.responsibleDepartment = template.responsibleDepartment;
                        }
                        else {
                            this.responsibleDepartment = null;
                        }
                    }
                    if (chatStatus !== CHAT_STATUS.PENDING) {
                        this.templateChatRadioStatus = TEMPLATE_RADIO_STATUSES.OTHER;
                    }
                    else
                        this.templateChatRadioStatus = TEMPLATE_RADIO_STATUSES.TAKEN;
                    if (template.replyText) {
                        this.templateReplyText = template.replyText;
                    }
                    else
                        this.templateReplyText = "";
                    if (template.setBotNode) {
                        this.setBotNode = template.setBotNode;
                    }
                    else
                        this.setBotNode = null;
                    if (template.attachedFile) {
                        this.attachedFile = template.attachedFile;
                    }
                    else
                        this.attachedFile = null;
                    if (this.rootStore.wizardStore.isWizardOpen) {
                        this.templateChatRadioStatus = TEMPLATE_RADIO_STATUSES.OTHER;
                    }
                    if (template.header) {
                        this.richTemplateHeaderType = template.header.type;
                        this.richTemplateHeaderUrl = template.header.url || '';
                        this.richTemplateHeader = template.header.text || null;
                    }
                    if (template.footer) {
                        this.richTemplateFooter = template.footer.text;
                    }
                    if (template.buttons) {
                        if (template.buttons.buttons[0] && template.buttons.buttons[0].type === RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY) {
                            this.richTemplateButtonType = RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY;
                            this.quickReplyButtons = template.buttons.buttons;
                        }
                        else {
                            this.richTemplateButtonType = RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION;
                            this.callToActionButtons = template.buttons.buttons;
                        }
                    }
                }
            }
        });
        Object.defineProperty(this, "setTemplateDeleting", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (template) => {
                this.currentTemplate = template;
                this.templateDeleting = !this.templateDeleting;
            }
        });
        Object.defineProperty(this, "handleSetUsage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                var _a;
                this.templateUsage = value;
                if (value === TEMPLATE_USAGE.BULK) {
                    this.handleSetRadioStatus(TEMPLATE_RADIO_STATUSES.OTHER);
                    if (((_a = this.currentTemplate) === null || _a === void 0 ? void 0 : _a.status) !== WhatsApp.TemplateLocalizationStatus.APPROVED) {
                        this.richTemplateFooter = TEMPLATE_FOOTER_MESSAGES[this.templateLanguage] || '';
                    }
                }
                else {
                    this.handleSetRadioStatus(TEMPLATE_RADIO_STATUSES.TAKEN);
                }
            }
        });
        Object.defineProperty(this, "handleSetTemplateLanguage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.templateLanguage = value;
            }
        });
        Object.defineProperty(this, "handleSetCategory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.templateCategory = value;
            }
        });
        Object.defineProperty(this, "handleSetDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.templateDepartments = value;
            }
        });
        Object.defineProperty(this, "handleCheckIfSampleParamsHaveBeenAdded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (hasAllSampleParamsAdded) => {
                if (hasAllSampleParamsAdded) {
                    this.submitTemplateError = null;
                    this.submitWhatsAppError = null;
                }
                else {
                    this.submitTemplateError = 'provideSampleData';
                    this.submitWhatsAppError = 'provideSampleData';
                }
            }
        });
        Object.defineProperty(this, "handleSetMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (message) => {
                var _a;
                const listOfPlaceholders = message.match(/{{(.*?)}}/g);
                let nextPlaceholderNumber;
                if (listOfPlaceholders) {
                    nextPlaceholderNumber = listOfPlaceholders.length + 1;
                }
                else {
                    nextPlaceholderNumber = 1;
                }
                this.templateMessage = message;
                this.nextPlaceholderNumber = nextPlaceholderNumber;
                if (((_a = this.bodyExampleParams) === null || _a === void 0 ? void 0 : _a.length) > nextPlaceholderNumber - 1) {
                    this.bodyExampleParams = this.bodyExampleParams.slice(0, nextPlaceholderNumber - 1);
                }
            }
        });
        Object.defineProperty(this, "handleAddPlaceholder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (start, end) => {
                const listOfPlaceholders = this.templateMessage.match(/{{(.*?)}}/g);
                let dynamicLinkCounter = 0;
                if (this.callToActionButtons && this.callToActionButtons.find(button => button.url && button.url.match(/{{(.*?)}}/g))) {
                    dynamicLinkCounter += 1;
                }
                if (listOfPlaceholders) {
                    this.nextPlaceholderNumber = listOfPlaceholders.length + 1 + dynamicLinkCounter;
                }
                const stringEnd = this.templateMessage.length;
                this.templateMessage = `${this.templateMessage.slice(0, start)}{{${this.nextPlaceholderNumber}}}${this.templateMessage.slice(end, stringEnd)}`;
                this.nextPlaceholderNumber += 1;
            }
        });
        Object.defineProperty(this, "handleAddEmoji", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (emoji, start, end) => {
                const stringEnd = this.templateMessage.length;
                this.templateMessage = `${this.templateMessage.slice(0, start)}${emoji}${this.templateMessage.slice(end, stringEnd)}`;
            }
        });
        Object.defineProperty(this, "handleSetTitle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.templateTitle = value;
            }
        });
        Object.defineProperty(this, "setTemplateName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (name) => {
                this.templateName = name;
            }
        });
        Object.defineProperty(this, "handleSetStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (templateChatStatus) => {
                this.templateFieldMissing = false;
                this.templateChatStatus = templateChatStatus;
            }
        });
        Object.defineProperty(this, "handleSetRadioStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (radioStatus) => {
                this.templateChatRadioStatus = radioStatus;
                if (radioStatus === TEMPLATE_RADIO_STATUSES.OTHER) {
                    if (this.templateChatStatus === CHAT_STATUS.RESOLVED) {
                        this.templateChatStatus = CHAT_STATUS.RESOLVED;
                    }
                    else if (this.responsibleAgent || this.responsibleDepartment) {
                        this.templateChatStatus = CHAT_STATUS.ASSIGNED;
                    }
                    else {
                        this.templateChatStatus = CHAT_STATUS.BOT;
                    }
                }
                else if (radioStatus === TEMPLATE_RADIO_STATUSES.TAKEN) {
                    this.templateChatStatus = CHAT_STATUS.ASSIGNED;
                }
            }
        });
        Object.defineProperty(this, "handleSetBotNode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.setBotNode = value;
            }
        });
        Object.defineProperty(this, "handleSetReplyText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                // this.templateFieldMissing = null
                this.templateReplyText = value;
            }
        });
        Object.defineProperty(this, "addEmojiToReply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (emoji, start, end) => {
                const stringEnd = this.templateReplyText.length;
                this.templateReplyText = `${this.templateReplyText.slice(0, start)}${emoji}${this.templateReplyText.slice(end, stringEnd)}`;
            }
        });
        Object.defineProperty(this, "setStoreToDefault", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.templateTitle = "";
                this.templateName = "";
                this.templateUsage = TEMPLATE_USAGE.INBOX;
                this.templateDepartments = [];
                this.templateLanguage = LANGUAGES_TYPES.HE;
                this.templateCategory = WhatsApp.TemplateCategory.MARKETING;
                this.templateMessage = "";
                this.nextPlaceholderNumber = 1;
                this.attachedFile = null;
                this.bodyExampleParams = [];
                this.bodyExampleTemporaryParams = [];
                this.currentTemplate = null;
                this.templateEditing = false;
                this.templateCreating = false;
                this.templateDeleting = false;
                this.submitTemplateLoading = false;
                this.submitTemplateError = null;
                this.submitWhatsAppError = null;
                this.submitWhatsAppLoading = false;
                this.templateChatStatus = CHAT_STATUS.BOT;
                this.templateChatRadioStatus = TEMPLATE_RADIO_STATUSES.TAKEN;
                this.setBotNode = "";
                this.templateReplyText = "";
                this.responsibleAgent = null;
                this.responsibleDepartment = null;
                this.richTemplateHeader = null;
                this.richTemplateHeaderType = null;
                this.richTemplateHeaderUrl = constants.DEFAULT_EXAMPLE_TEMPLATE_IMG_URL;
                this.richTemplateFooter = null;
                this.richTemplateButtonType = null;
                this.quickReplyButtons = null;
                this.callToActionButtons = null;
                this.lastSyncOn = {};
            }
        });
        Object.defineProperty(this, "setFieldError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.templateFieldMissing = true;
            }
        });
        //Rich template params functions
        Object.defineProperty(this, "handleRichTemplateHeaderType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.richTemplateHeaderType = value;
            }
        });
        Object.defineProperty(this, "handleSetHeader", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                if (typeof value !== "string") {
                    this.richTemplateHeader = value[0];
                }
                else {
                    this.richTemplateHeader = value;
                }
            }
        });
        Object.defineProperty(this, "handleSetFooter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.richTemplateFooter = value;
            }
        });
        Object.defineProperty(this, "handleSetButtonType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                if (value === RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY) {
                    this.callToActionButtons = null;
                    this.quickReplyButtons = [{ text: "" }];
                }
                else if (value === RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION) {
                    this.quickReplyButtons = null;
                    this.callToActionButtons = [{ type: CALL_TO_ACTION_TYPES.URL, text: "", url: "" }];
                }
                else {
                    this.callToActionButtons = null;
                    this.quickReplyButtons = null;
                }
                this.richTemplateButtonType = value;
            }
        });
        Object.defineProperty(this, "handleAddButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (type, actionType) => {
                if (type === RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY) {
                    this.quickReplyButtons = [...this.quickReplyButtons, { text: "" }];
                }
                else if (type === RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION) {
                    this.callToActionButtons = [...this.callToActionButtons, { type: actionType === CALL_TO_ACTION_TYPES.URL ? CALL_TO_ACTION_TYPES.PHONE_NUMBER : CALL_TO_ACTION_TYPES.URL, text: "", url: "" }];
                }
            }
        });
        Object.defineProperty(this, "handleRemoveButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (type, index) => {
                if (type === RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY) {
                    const list = [...this.quickReplyButtons];
                    list.splice(index, 1);
                    this.quickReplyButtons = list;
                }
                else if (type === RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION) {
                    const list = [...this.callToActionButtons];
                    list.splice(index, 1);
                    this.callToActionButtons = list;
                }
            }
        });
        Object.defineProperty(this, "handleButtonTextChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value, index, type, fieldName) => {
                if (type === RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY) {
                    const list = [...this.quickReplyButtons];
                    list[index][fieldName] = value;
                    this.quickReplyButtons = list;
                }
                else if (type === RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION) {
                    const list = [...this.callToActionButtons];
                    list[index][fieldName] = value;
                    this.callToActionButtons = list;
                    if (fieldName === "url" && new RegExp(/{{(.*?)}}/g).test(value)) {
                        this.templateUsage = TEMPLATE_USAGE.BULK;
                    }
                }
            }
        });
        Object.defineProperty(this, "changeTempExampleParam", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (index, value) => {
                const params = [...this.bodyExampleTemporaryParams];
                params[index] = value;
                this.bodyExampleTemporaryParams = params;
            }
        });
        Object.defineProperty(this, "setTempExampleParam", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                var _a;
                const altValue = Array((_a = this.templateMessage.match(/{{(.*?)}}/g)) === null || _a === void 0 ? void 0 : _a.length);
                this.bodyExampleTemporaryParams = value || altValue;
            }
        });
        Object.defineProperty(this, "setTemplateExamples", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (examples) => {
                this.bodyExampleParams = examples.filter(e => !isNil(e));
            }
        });
        Object.defineProperty(this, "clearRichParams", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.richTemplateHeader = null;
                this.richTemplateHeaderType = null;
                this.richTemplateHeaderUrl = constants.DEFAULT_EXAMPLE_TEMPLATE_IMG_URL;
                this.richTemplateFooter = null;
                this.richTemplateButtonType = null;
                this.quickReplyButtons = null;
                this.callToActionButtons = null;
                this.bodyExampleParams = [];
            }
        });
        Object.defineProperty(this, "showCancelNotifyModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (state) => {
                this.showManagerCancelModal = state;
            }
        });
        Object.defineProperty(this, "setSelectedAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (account) => {
                this.selectedAccountId = account;
            }
        });
        Object.defineProperty(this, "syncAndLoadTemplateMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (accountId) => __awaiter(this, void 0, void 0, function* () {
                yield this.syncTemplateMessages(accountId).catch(err => {
                    console.error('Error syncing templates', err);
                });
                yield this.loadTemplateMessages(accountId).catch(err => {
                    console.error('Error loading templates', err);
                });
            })
        });
        Object.defineProperty(this, "setIsDefaultTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (isDefault) => {
                this.isDefaultTemplate = isDefault;
            }
        });
        /**
         * It not very clear why this function exists
         *
         * @param templateMessage
         */
        Object.defineProperty(this, "parseTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (templateMessage) => {
                if (templateMessage.provider_template.localizations.filter(Boolean).length === 0) {
                    const language = Object.keys(templateMessage.localizationDrafts || {})[0];
                    if (language && templateMessage.localizationDrafts) {
                        templateMessage.provider_template.localizations = [templateMessage.localizationDrafts[language]];
                    }
                }
                const status = templateMessage.provider_template.localizations[0].status;
                const title = templateMessage.title;
                const name = templateMessage.name;
                const usage = templateMessage.usage;
                const chatStatus = templateMessage.chatStatus;
                const setBotNode = templateMessage.setBotNode;
                const replyText = templateMessage.replyText;
                const category = templateMessage.provider_template.category;
                const departments = templateMessage.departments;
                const rejectionReason = templateMessage.provider_template.localizations[0].rejectionReason;
                const responsibleAgent = templateMessage.responsibleAgent;
                const responsibleDepartment = templateMessage.responsibleDepartment;
                const attachedFile = templateMessage.attachedFile;
                const updatedAt = new Date(templateMessage.updatedAt);
                const createdAt = new Date(templateMessage.created);
                const isDefault = templateMessage.isDefault;
                let bodyExampleParams;
                let header;
                let footer;
                let buttons;
                let text;
                templateMessage.provider_template.localizations[0].components.forEach(component => {
                    var _a, _b, _c;
                    if (component.type === WhatsApp.TemplateComponentType.HEADER) {
                        header = {
                            type: component.format,
                            text: component.format === WhatsApp.TemplateComponentFormat.TEXT ? component.text : null,
                            url: [WhatsApp.TemplateComponentFormat.DOCUMENT, WhatsApp.TemplateComponentFormat.IMAGE, WhatsApp.TemplateComponentFormat.VIDEO].includes(component.format) ? (_b = (_a = component === null || component === void 0 ? void 0 : component.example) === null || _a === void 0 ? void 0 : _a.header_handle) === null || _b === void 0 ? void 0 : _b[0] : null,
                        };
                    }
                    if (component.type === WhatsApp.TemplateComponentType.BODY) {
                        text = component.text;
                        if (component.example) {
                            bodyExampleParams = (_c = component.example.body_text) === null || _c === void 0 ? void 0 : _c[0];
                        }
                    }
                    if (component.type === WhatsApp.TemplateComponentType.FOOTER) {
                        footer = component;
                    }
                    if (component.type === WhatsApp.TemplateComponentType.BUTTONS) {
                        buttons = {
                            type: component.buttons[0] ? component.buttons[0].type : null,
                            buttons: component.buttons
                        };
                    }
                });
                return {
                    _id: templateMessage._id,
                    header,
                    text,
                    footer,
                    buttons,
                    language: templateMessage.provider_template.localizations[0].language,
                    obj: templateMessage.provider_template,
                    title: title || "",
                    status,
                    rejectionReason,
                    usage,
                    departments,
                    category,
                    name,
                    chatStatus,
                    setBotNode,
                    replyText,
                    responsibleAgent,
                    responsibleDepartment,
                    attachedFile,
                    updatedAt,
                    createdAt,
                    bodyExampleParams,
                    isDefault,
                };
            }
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.rootStore.events
            .addListener(EventName.template_updated, (data) => this.onTemplateUpdatedEvent(data))
            .addListener(EventName.template_deleted, (data) => this.onTemplateDeletedEvent(data));
        reaction(() => { var _a; return (_a = this.selectedAccountId) === null || _a === void 0 ? void 0 : _a.id; }, () => {
            const { id, name } = this.selectedAccountId || {};
            if (id && name === MessagingChannel.whatsapp) {
                this.syncAndLoadTemplateMessages(id);
            }
        });
        reaction(() => this.hasAllSampleParamsAdded, (hasAllSampleParamsAdded) => {
            this.handleCheckIfSampleParamsHaveBeenAdded(hasAllSampleParamsAdded);
        });
    }
    get hasSampleParamsSetup() {
        var _a;
        const regex = /{{\d}}/g; // {{1}} any digit within double curly braces
        return (_a = this.templateMessage) === null || _a === void 0 ? void 0 : _a.match(regex);
    }
    get hasAtLeastOneSampleParamAdded() {
        const hasParams = (this.bodyExampleParams || []).filter((p) => p !== '').length > 0;
        const hasHeader = !!this.richTemplateHeaderUrl;
        return hasParams || hasHeader;
    }
    get hasAllSampleParamsAdded() {
        const hasBodyParams = () => {
            var _a;
            if (!this.hasSampleParamsSetup)
                return true;
            return ((_a = this.bodyExampleParams) === null || _a === void 0 ? void 0 : _a.filter((p) => p !== '').length) >= this.nextPlaceholderNumber - 1;
        };
        const hasHeaderParams = () => {
            if (!this.richTemplateHeaderType)
                return true;
            if ([TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.VIDEO].includes(this.richTemplateHeaderType)) {
                if (this.richTemplateHeader)
                    return true;
                return !!this.richTemplateHeaderUrl && this.richTemplateHeaderUrl !== constants.DEFAULT_EXAMPLE_TEMPLATE_IMG_URL;
            }
            return true;
        };
        return hasBodyParams() && hasHeaderParams();
    }
    get account() {
        const account = this.rootStore.channels.accounts.find((acc) => { var _a; return acc.accountId === ((_a = this.selectedAccountId) === null || _a === void 0 ? void 0 : _a.id); });
        if (account) {
            return account;
        }
        return { accountId: '' };
    }
    get defaultTemplate() {
        var _a, _b;
        const accountId = (_a = this.selectedAccountId) === null || _a === void 0 ? void 0 : _a.id;
        if (!accountId)
            return null;
        const template = (_b = this.templateMessagesLists[accountId]) === null || _b === void 0 ? void 0 : _b.find((tmp) => tmp.isDefault);
        if (template) {
            return template;
        }
        return null;
    }
    get templateMessagesList() {
        if (!this.selectedAccountId)
            return [];
        return this.templateMessagesLists[this.selectedAccountId.id] || [];
    }
    get selectedChannelBot() {
        var _a;
        const account = this.selectedAccountId ? this.rootStore.channels.getAccount(this.selectedAccountId) : null;
        return (_a = account === null || account === void 0 ? void 0 : account.bot) !== null && _a !== void 0 ? _a : null;
    }
    onTemplateUpdatedEvent(tmplUpdated) {
        var _a, _b;
        const { accountId } = tmplUpdated.channelInfo;
        // re-casting it to get the _id property, probably something to consider changing in the lib
        const newTemplate = tmplUpdated;
        const templateExists = (_a = this.templateMessagesLists[accountId]) === null || _a === void 0 ? void 0 : _a.find((tmpl) => tmpl._id === newTemplate._id);
        if (!templateExists) {
            this.templateMessagesLists[accountId] = [
                this.parseTemplate(newTemplate),
                ...(this.templateMessagesLists[accountId] || []),
            ];
        }
        else {
            this.templateMessagesLists[accountId] =
                (_b = this.templateMessagesLists[accountId]) === null || _b === void 0 ? void 0 : _b.map((tmpl) => {
                    return newTemplate._id === tmpl._id ? this.parseTemplate(newTemplate) : tmpl;
                });
        }
    }
    onTemplateDeletedEvent(data) {
        Object.entries(this.templateMessagesLists).forEach(([accountId, templates]) => {
            this.templateMessagesLists[accountId] = templates.filter((tmpl) => tmpl._id !== data._id);
        });
    }
}
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateMessagesLists", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateMessagesLoading", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "isNewTemplateDrawer", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateTitle", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateName", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateUsage", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateDepartments", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateLanguage", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateCategory", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateMessage", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "nextPlaceholderNumber", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "currentTemplate", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateEditing", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateCreating", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateDeleting", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateChatStatus", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateChatRadioStatus", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "setBotNode", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateReplyText", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "responsibleAgent", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "responsibleDepartment", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "attachedFile", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "botNodeLists", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "botNodeListLoading", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateFieldMissing", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "isDefaultTemplate", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "submitTemplateLoading", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "submitTemplateError", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "submitWhatsAppLoading", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "submitWhatsAppError", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "richTemplateHeader", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "richTemplateHeaderType", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "richTemplateHeaderUrl", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "richTemplateFooter", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "richTemplateButtonType", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "quickReplyButtons", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "callToActionButtons", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "bodyExampleParams", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "bodyExampleTemporaryParams", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "showManagerCancelModal", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateTierLimitCount", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "templateTierLimitLoading", void 0);
__decorate([
    observable
], TemplateMessagesStore.prototype, "selectedAccountId", void 0);
__decorate([
    computed
], TemplateMessagesStore.prototype, "hasSampleParamsSetup", null);
__decorate([
    computed
], TemplateMessagesStore.prototype, "hasAtLeastOneSampleParamAdded", null);
__decorate([
    computed
], TemplateMessagesStore.prototype, "hasAllSampleParamsAdded", null);
__decorate([
    computed
], TemplateMessagesStore.prototype, "account", null);
__decorate([
    computed
], TemplateMessagesStore.prototype, "defaultTemplate", null);
__decorate([
    computed
], TemplateMessagesStore.prototype, "templateMessagesList", null);
__decorate([
    computed
], TemplateMessagesStore.prototype, "selectedChannelBot", null);
__decorate([
    action
], TemplateMessagesStore.prototype, "fetchBotNodeList", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "sendTemplateMessage", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "createTemplate", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "toggleNewTemplateDrawer", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleEditTemplate", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleDeleteTemplate", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSubmitToWhatsApp", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "getTierLimitCount", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setResponsibleAgentOrDepartment", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setAttachedFile", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setNewTemplate", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setTemplateEdited", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setTemplateDeleting", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetUsage", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetTemplateLanguage", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetCategory", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetDepartment", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleCheckIfSampleParamsHaveBeenAdded", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetMessage", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleAddPlaceholder", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleAddEmoji", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetTitle", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setTemplateName", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetStatus", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetRadioStatus", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetBotNode", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetReplyText", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "addEmojiToReply", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setStoreToDefault", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setFieldError", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleRichTemplateHeaderType", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetHeader", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetFooter", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleSetButtonType", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleAddButton", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleRemoveButton", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "handleButtonTextChange", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "changeTempExampleParam", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setTempExampleParam", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setTemplateExamples", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "clearRichParams", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "showCancelNotifyModal", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setSelectedAccount", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "setIsDefaultTemplate", void 0);
__decorate([
    action
], TemplateMessagesStore.prototype, "onTemplateUpdatedEvent", null);
__decorate([
    action
], TemplateMessagesStore.prototype, "onTemplateDeletedEvent", null);
export default TemplateMessagesStore;
